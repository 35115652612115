import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'utils/axiosUtil';
import { KARTE_INFO, KARTE_LIST } from 'constants/apiURL';
import { loadingActions } from 'slices/loadingSlice';

const initialState = {
  karteInfo: {},
  selectedSrySeq: null,
  showDetailModal: false,
  karteInfoDetail: null,
};

export const fetchKarteInfo = createAsyncThunk(
  'fetchKarteInfo',
  async (params, { dispatch }) => {
    dispatch(loadingActions.setPrimaryLoading(true));

    const res = await axios.post(KARTE_LIST, params);

    dispatch(loadingActions.setPrimaryLoading(false));

    return res;
  }
);
export const fetchKarteInfoDetail = createAsyncThunk(
  'fetchKarteInfoDetail',
  async params => {
    const res = await axios.post(KARTE_INFO, {
      kaiincrdNo: params.cardNumber,
      srySeq: params.selectedSrySeq,
      partId: params.partId,
    });
    return { ...params, ...res.body };
  }
);

export const karteSlice = createSlice({
  name: 'karteSlice',
  initialState,
  reducers: {
    setSelectedSrySeq: (state, { payload }) => {
      state.selectedSrySeq = payload;
    },
    showDetailModal: (state, { payload }) => {
      state.showDetailModal = true;
      state.karteInfoDetail = payload;
    },
    closeDetailModal: state => {
      state.showDetailModal = false;
      state.karteInfoDetail = null;
    },
    clear: () => initialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchKarteInfo.fulfilled, (state, { payload }) => {
      state.karteInfo = payload.body;
    });
    builder.addCase(fetchKarteInfoDetail.fulfilled, (state, { payload }) => {
      state.showDetailModal = true;
      state.karteInfoDetail = payload;
    });
  },
});

export const actions = karteSlice.actions;
export default karteSlice.reducer;
