import Path from 'constants/routePath';
import { encode } from 'utils/base64Util';

/**
 * タイヤ保管サービスを別タブで開く
 *
 * @param abId カード番号
 * @param reserveId 予約番号（新規登録時は不要）
 */
export const openTirePage = (abId, reserveId) => {
  const form_id = 'temp-tire-form';
  const window_name = 'new_window';

  // 新規タブを開く
  window.open('', window_name);

  // フォーム作成
  let form = document.createElement('form');
  form.action = Path.TIRE;
  form.method = 'post';
  form.style.display = 'none';
  form.target = window_name;
  form.id = form_id;
  document.body.appendChild(form);

  // 流入区分
  let influxInput = document.createElement('input');
  influxInput.name = 't1';
  influxInput.value = encode('WB');
  form.appendChild(influxInput);

  // ABID
  let abIdInput = document.createElement('input');
  abIdInput.name = 't2';
  abIdInput.value = encode(abId);
  form.appendChild(abIdInput);

  // 予約番号
  if (reserveId) {
    let reserveIdInput = document.createElement('input');
    reserveIdInput.name = 't3';
    reserveIdInput.value = encode(reserveId);
    form.appendChild(reserveIdInput);
  }

  // 送信
  form.submit();

  // フォーム削除削除
  document.getElementById(form_id).remove();
};
