import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { openTirePage } from 'utils/tireRequestUtil';
import { getTermKbn } from 'utils/otherUtil';
import { carPrecautions, commonPrecautions } from 'constants/precautions';
import { actions, reservationReflection } from 'slices/reservationConfirmSlice';
import { messageModalActions } from 'slices/messageModalSlice';
import { actions as loginActions } from 'slices/loginSlice';
import classNames from 'classnames';
import Yup from 'utils/yupUtil';
import { COMMON_ERROR_MESSAGE } from 'utils/axiosUtil';
import Stepper from 'components/stepper';
import Proctitle from 'components/proctitle';
import InputText from 'components/inputText';
import InputTextArea from 'components/inputTextArea';
import InputTitleLabel from 'components/inputTitleLabel';
import Checkbox from 'components/checkbox';
import AprosButton from 'components/aprosButton';
import Linked from 'components/linked';
import Path, { ERROR_MESSAGE_TRANSITION } from 'constants/routePath';
import { SERVICE_CODE_TIRE } from 'constants/service';
import Icons from 'images/icons';
import dayjs from 'dayjs';
import ServiceNameLabel from './components/serviceNameLabel';
import { useConfirmHistoryBack } from 'hooks/useConfirmHistoryBack';

const Confirm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isPc, isSp, isTablet } = useDeviceSize();

  // 編集したらブラウザバックで警告を表示する
  const [isEdited, setIsEdited] = useState(false);
  useConfirmHistoryBack({ isEdited, setIsEdited });

  const {
    yykInfo,
    requiredTime,
    stepLabels,
    baseScreen,
    previousStep,
    serviceSelectScreen,
    selectService,
    queryParams,
    isTireContract,
    externalSelectSerivce,
    bikoDispKbn,
    bikoLead,
  } = location.state || {};

  const {
    work = [{}],
    tokCd,
    tokNmRyk,
    sryMkrMei,
    sshMei,
    sgyYmd,
    sgyStaHm,
    srySeq,
    yykNo,
    yykGetYmd,
    yykGetHms,
    yykKbn,
    ecOrderNo,
    kykBiko,
    tenpBiko,
    mbSendFlg,
    otherMailAdr,
    pcSendFlg,
  } = yykInfo || {};

  const [{ shobun = [], chubunCd }] = work || [];

  const {
    accountInfo: {
      cardNumber,
      mailAddressPc,
      mailAddressMobile,
      userId,
      userName,
      userNameKana,
      kaiinKeitaiNoSgis,
      kaiinKeitaiNoJgsid,
      kaiinKeitaiNoJinno,
      kaiinTelNoSgi,
      kaiinTelNoSnk,
      kaiinTelNoJinno,
    },
  } = useSelector(state => state.account);

  const { isShowTireModal } = useSelector(state => state.reservationConfirm);

  const { isMobila } = useSelector(state => state.mobila);

  const [isError, setIsError] = useState(false);

  const [isBottomVisible, setIsBottomVisible] = useState(true);

  const [isConfirmCheckboxVisible, setIsConfirmCheckboxVisible] =
    useState(false);

  const isModify = baseScreen === Path.RESERVATION_LIST;

  const CONFIRM_TITLES = [
    { label: '予約内容', value: '' },
    { label: '予約店舗', value: tokNmRyk },
    {
      label: '予約車両',
      value: `${sryMkrMei && sshMei ? `${sryMkrMei} ${sshMei}` : '未登録車両'}`,
    },
    { label: 'お名前', value: userName },
    { label: 'フリガナ', value: userNameKana },
    {
      label: '自宅・携帯',
      value:
        `${kaiinKeitaiNoSgis ?? ''}${kaiinKeitaiNoJgsid ?? ''}${
          kaiinKeitaiNoJinno ?? ''
        }` ||
        `${kaiinTelNoSgi ?? ''}${kaiinTelNoSnk ?? ''}${kaiinTelNoJinno ?? ''}`,
    },
    {
      label: '予約確認メール宛先',
      required: true,
    },
  ];

  // 備考欄を別途追加したので削除
  // let newLineKykBiko;
  // if (isModify && kykBiko) {
  //   newLineKykBiko = kykBiko;
  //   CONFIRM_TITLES[3] = {
  //     label: 'その他',
  //     value: newLineKykBiko,
  //   };
  // }

  if (bikoDispKbn === '1') {
    CONFIRM_TITLES[7] = {
      label: '備考欄',
      value: bikoLead,
    };
  }

  const formik = useFormik({
    initialValues: {
      mailAddressMobileCheck: !!mailAddressMobile && mbSendFlg !== '0',
      mailAddressPcCheck: !!mailAddressPc && pcSendFlg !== '0',
      otherMailAddressCheck: !!otherMailAdr,
      otherAdr: otherMailAdr || '',
      confirm: false,
      biko: kykBiko || '',
    },
    validateOnMount: true,
    validationSchema: Yup.object({
      mailAddressMobileCheck: Yup.boolean()
        .nullable()
        .test(
          'mailAddressMobileCheck',
          '予約確認メール宛先を選択してください',
          (value, testContext) => {
            const { mailAddressPcCheck, otherMailAddressCheck, otherAdr } =
              testContext.parent;
            return (
              value ||
              (mailAddressPcCheck && mailAddressPc) ||
              (otherMailAddressCheck && otherAdr)
            );
          }
        ),
      mailAddressPcCheck: Yup.boolean()
        .nullable()
        .test(
          'mailAddressPcCheck',
          '予約確認メール宛先を選択してください',
          (value, testContext) => {
            const { mailAddressMobileCheck, otherMailAddressCheck, otherAdr } =
              testContext.parent;
            return (
              value ||
              (mailAddressMobileCheck && mailAddressMobile) ||
              (otherMailAddressCheck && otherAdr)
            );
          }
        ),
      otherAdr: Yup.string().when('otherMailAddressCheck', {
        is: otherMailAddressCheck => otherMailAddressCheck,
        then: Yup.string().mail().required(),
        otherwise: Yup.string(),
      }),
      confirm: Yup.boolean().checked(),
    }),
  });

  useEffect(() => {
    // 遷移パラメータが不正な場合、エラーを出してTOP画面に遷移
    if (!yykInfo) {
      const message = ERROR_MESSAGE_TRANSITION;
      dispatch(messageModalActions.showMessageModal({ message }));
      dispatch(loginActions.closeAbidDifferenceModal());
      navigate(Path.TOP);
      return;
    }
    selectService?.aprosCode === SERVICE_CODE_TIRE &&
      isTireContract &&
      setTimeout(() => {
        dispatch(actions.showTireModal());
      }, 1500);
    return () => {
      dispatch(actions.clear());
    };
  }, [dispatch]);

  const onChangeMailCheck = (fieldName, value) => {
    formik.setFieldValue(fieldName, value);
    setTimeout(() => formik.setFieldTouched(fieldName, true));
  };

  const {
    mailAddressMobileCheck,
    mailAddressPcCheck,
    otherMailAddressCheck,
    otherAdr,
    biko,
  } = formik.values;

  const scrollToBottom = () => {
    const caution = document.querySelector('#caution');

    document.getElementById('root').scrollTo({
      top: caution.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  let confimbottom = document.querySelector('#confimbottom');

  const handleScroll = () => {
    if (isInDisplay()) {
      setIsBottomVisible(false);
    } else {
      setIsBottomVisible(true);
    }
  };

  const isInDisplay = () => {
    if (!confimbottom) return false;
    const { top, bottom } = confimbottom?.getBoundingClientRect();
    return top >= 0 && bottom <= window.innerHeight;
  };

  const clickDummyButton = () => {
    setIsConfirmCheckboxVisible(true);
  };

  const clickConfirmCheckbox = () => {
    if (!formik.isValid || !isError) {
      setIsConfirmCheckboxVisible(false);
    }
  };

  useEffect(() => {
    setIsConfirmCheckboxVisible(false);
    confimbottom = document.querySelector('#confimbottom');
    document.querySelector('#root').addEventListener('scroll', handleScroll);
    return () => {
      document
        .querySelector('#root')
        .removeEventListener('scroll', handleScroll);
    };
  }, []);

  const bottomElement = () => (
    <div className="flex-col border border-line bg-background-sub text-left">
      <div
        className={classNames('my-4 flex flex-row items-center', {
          'justify-center': !isTablet || !isModify,
          'mx-4': isTablet && isModify,
        })}
      >
        {isSp && <Icons.Image src={Icons.alert} className="h-10 w-10" />}
        {isSp && (
          <div className="notice-area mx-1 flex flex-col">
            <label className="text-base whitespace-nowrap font-bold">
              {isModify
                ? '日時の変更がまだ確定していません'
                : '予約はまだ確定していません'}
            </label>
            <label className="text-sm mb-[2px]">
              予約内容と注意事項を確認してください
            </label>

            <div>
              <Linked
                text="注意事項の確認・予約確定へ進む"
                isLine={true}
                linkIcon={'R'}
                className="text-sm self-center"
                onClick={() => scrollToBottom()}
              />
            </div>
          </div>
        )}
        {isSp || (
          <div className="flex flex-col items-center">
            <div className="box-border flex flex-row items-center">
              <Icons.Image src={Icons.alert} className="h-10 w-10" />
              <div className="notice-area mx-1 flex flex-row items-center">
                <label className="text-base mr-4 whitespace-nowrap font-bold">
                  {isModify
                    ? '日時の変更がまだ確定していません'
                    : '予約はまだ確定していません'}
                </label>
                <div className="text-sm">
                  予約内容と注意事項を確認してください
                </div>
              </div>
            </div>
            <div>
              <Linked
                text="注意事項の確認・予約確定へ進む"
                isLine={true}
                linkIcon={'R'}
                className="text-sm mt-[16px] self-center"
                onClick={() => scrollToBottom()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );

  const tireModal = () => (
    <div
      className={classNames(
        'fixed bottom-10 right-0 z-50 ml-4 w-[310px] rounded border border-line bg-white px-4 pb-4 pt-3.5 transition-all sm:right-4 sm:w-[368px] sm:p-6 lg:right-4 lg:w-[600px] lg:px-10 lg:py-8',
        isShowTireModal
          ? 'opacity-100 duration-1000'
          : 'pointer-events-none opacity-0 duration-200'
      )}
    >
      <Icons.Image
        src={Icons.closeModal}
        className="absolute right-0 top-0 mr-2 mt-2 h-6 w-6 cursor-pointer sm:h-8 sm:w-8 lg:mr-4 lg:mt-4"
        onClick={() => dispatch(actions.closeTireModal())}
      />
      <div className="flex flex-row">
        <Icons.Image src={Icons.alert} />
        <div
          className={classNames(
            'ml-2 flex flex-col font-bold text-red',
            isPc ? 'text-[22px]' : 'text-sm'
          )}
        >
          <label>保管サービスご利用中の</label>
          <label>タイヤ交換ではございませんか？</label>
        </div>
      </div>
      <div className="text-sm mt-2.5 flex flex-col text-navy sm:mt-4 lg:mt-6">
        <label>
          こちらのフォームでは、保管サービスご利用のタイヤ交換予約を承ることができません。(保管中のタイヤをご予約日までにご用意できません。)
        </label>
        <label>
          保管サービスご利用中のお車のタイヤ交換予約は、以下ボタンから改めてご予約をお願いいたします。
        </label>
      </div>
      <div className="mt-4 flex justify-center sm:mt-6 lg:mt-[29px]">
        <AprosButton
          comClassName="main"
          className="w-[260px] sm:w-[300px]"
          text={
            <label className="text-[14px] sm:text-[18px]">
              保管サービス専用予約ページへ
            </label>
          }
          onClick={() => openTirePage(cardNumber, yykNo)}
        />
      </div>
      <div className="mt-7 flex justify-center">
        <Linked
          text="閉じる"
          isLine={true}
          className="text-sm font-bold"
          onClick={() => dispatch(actions.closeTireModal())}
        />
      </div>
    </div>
  );

  return (
    <>
      <Stepper
        labels={stepLabels}
        previousStep={previousStep}
        activeStep={stepLabels?.length - 1}
        bottomElement={bottomElement()}
        fullWidth={true}
        className={{
          stepperClass: 'mb-10 max-sm:mx-12 sm:mx-36',
          bottomClass: 'mx-0 h-full w-full text-left',
        }}
        onClick={index => {
          const params = {
            state: {
              yykInfo,
              stepLabels,
              baseScreen,
              previousStep: stepLabels.length - 1,
              serviceSelectScreen,
              queryParams,
            },
          };
          if (index === 0) {
            if (baseScreen === Path.RESERVATION_SHOP_SELECT) {
              navigate(Path.RESERVATION_SHOP_SELECT, params);
            } else if (baseScreen === Path.RESERVATION_SERVICE_SELECT) {
              navigate(Path.RESERVATION_SERVICE_SELECT, params);
            } else if (baseScreen === Path.RESERVATION_LIST) {
              navigate(Path.RESERVATION_DATE_SELECT, params);
            } else if (baseScreen === Path.RESERVATION_DATE_SELECT) {
              navigate(Path.RESERVATION_DATE_SELECT, params);
            }
          } else if (index === 1) {
            navigate(Path.RESERVATION_DATE_SELECT, params);
          }
        }}
      />
      <div className="mt-10 sm:mt-14 lg:mt-20">
        <Proctitle text="予約内容確認" useH1={true} />
        {isError && (
          <div className="mt-4 flex flex-col items-center justify-center border border-red bg-calendar-sunday px-2 py-2 sm:px-4 sm:py-4 lg:flex-row lg:justify-between lg:px-6 lg:py-8">
            <div className="flex flex-row items-center">
              <Icons.Image src={Icons.alert} className="h-[35px] w-[35px]" />
              <div className="ml-1 sm:ml-2 lg:ml-[25px]">
                <label className="text-sm whitespace-pre-wrap font-medium text-red">
                  下記日時に選択した車両で、既に同じピットサービスが予約されているため予約が確定できません。
                  {isSp ? '' : '\n'}
                  ご予約内容を確認の上、再度選択し直してください。
                </label>
              </div>
            </div>
            <div className="mb-2 mt-4 flex flex-row justify-center sm:mb-0 lg:mt-0">
              <AprosButton
                text="予約内容を確認する"
                comClassName="reserve"
                onClick={() => navigate(Path.RESERVATION_LIST)}
              />
            </div>
          </div>
        )}
        <div className="mt-6 flex flex-col gap-y-6 sm:mt-14 sm:gap-y-10 lg:mt-16">
          {CONFIRM_TITLES.map(confirm => (
            <div
              key={confirm.label}
              className="text-sm flex flex-col items-start font-medium sm:flex-row"
            >
              <InputTitleLabel
                title={confirm.label}
                required={confirm.required}
                className="w-60 sm:w-80 lg:w-80"
              />
              <div className="flex w-full flex-col pl-4">
                {confirm.label === '予約内容' ? (
                  <>
                    <label className="mt-2">{`${dayjs(sgyYmd).format(
                      'YYYY年M月D日(ddd)'
                    )} ${dayjs(sgyStaHm, 'HHmm').format('HH:mm')}`}</label>
                    <div className="mt-2 sm:mt-10">
                      <ServiceNameLabel
                        selectService={selectService}
                        externalSelectSerivce={externalSelectSerivce}
                        className={{ labelClass: 'text-sm font-medium' }}
                      />
                    </div>
                  </>
                ) : confirm.label === '予約確認メール宛先' ? (
                  <div className="mt-2 flex flex-col gap-y-6 sm:mt-0 lg:gap-y-10">
                    {mailAddressMobile && (
                      <Checkbox
                        formik={formik}
                        fieldName={'mailAddressMobileCheck'}
                        text={mailAddressMobile}
                        className={{ boxClass: 'my-0' }}
                        onChange={e => {
                          setIsEdited(true);
                          onChangeMailCheck(
                            'mailAddressMobileCheck',
                            e.target.checked
                          );
                        }}
                      />
                    )}
                    {mailAddressPc && (
                      <Checkbox
                        formik={formik}
                        fieldName={'mailAddressPcCheck'}
                        text={mailAddressPc}
                        className={{ boxClass: 'my-0' }}
                        onChange={e => {
                          setIsEdited(true);
                          onChangeMailCheck(
                            'mailAddressPcCheck',
                            e.target.checked
                          );
                        }}
                      />
                    )}
                    <div className="flex flex-col gap-y-2 lg:-mt-3 lg:flex-row lg:items-center">
                      <Checkbox
                        formik={formik}
                        fieldName={'otherMailAddressCheck'}
                        text={'その他のメールアドレス'}
                        className={{ boxClass: 'my-0' }}
                        onChange={e => {
                          setIsEdited(true);
                          onChangeMailCheck(
                            'otherMailAddressCheck',
                            e.target.checked
                          );
                        }}
                      />
                      <InputText
                        formik={formik}
                        fieldName="otherAdr"
                        placeholder="メールアドレスを入力してください"
                        disabled={!!!formik.values.otherMailAddressCheck}
                        isShowErrorArea={true}
                        className={{
                          boxClass: 'p-0 lg:w-[442px]',
                          inputClass: 'w-full lg:mt-0',
                        }}
                        isLongError={true}
                        isErrorAreaAbsolute={true}
                      />
                    </div>
                  </div>
                ) : confirm.label === '備考欄' ? (
                  <>
                    {bikoLead !== '' && (
                      <div className="mb-2">
                        <label>{bikoLead}</label>
                      </div>
                    )}
                    <div>
                      <InputTextArea
                        formik={formik}
                        fieldName="biko"
                        placeholder="何かありましたらご記入ください。&#13;※ご予約以外の作業をご記入いただいても、作業できない場合がございます。"
                        isShowErrorArea={false}
                        className={{
                          inputClass: 'w-full lg:mt-0',
                        }}
                        style={{ resize: 'none' }}
                        disabled={isModify}
                        isLongError={false}
                      />
                    </div>
                  </>
                ) : (
                  <label className="mt-2sm:mt-0 whitespace-pre-line">
                    {confirm.value}
                  </label>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-8 border border-line bg-white sm:mt-16" id="caution">
        <div className="flex h-11 items-center bg-background-itemName">
          <label className="text-sm ml-[16px] font-bold lg:ml-[30px]">
            注意事項
          </label>
        </div>
        <div className="scrollBar text-sm mb-4 ml-4 mr-2 mt-4 h-[306px] overflow-y-scroll bg-white pr-1 font-medium sm:h-[352px] sm:pr-3 lg:mb-8 lg:ml-8 lg:mr-5 lg:mt-8 lg:pr-2">
          {['003', '022', '029'].includes(chubunCd)
            ? carPrecautions
            : commonPrecautions}
        </div>
      </div>
      <div
        id="confirmCheckbox"
        className={`text-sm mb-[-20px] mt-4 w-full text-center font-bold text-red sm:mt-8 lg:mb-[-26px] lg:mt-[24px] ${
          isConfirmCheckboxVisible ? 'block' : 'hidden'
        }
        `}
      >
        注意事項を確認の上、チェックを入れてください。
      </div>
      <div className="mt-6 flex flex-col items-center sm:mt-8 lg:mt-10">
        <Checkbox
          formik={formik}
          fieldName={'confirm'}
          text={'注意事項を確認しました'}
          className={{ boxClass: 'my-0 mb-6' }}
          onChange={e => {
            setIsEdited(true);
            formik.setFieldValue('confirm', e.target.checked);
            setTimeout(() => formik.setFieldTouched('confirm', true));
            clickConfirmCheckbox();
          }}
        />
        <div
          id="confimbottom"
          className="float-left clear-both mb-[-1px] h-[1px] w-full"
        >
          &emsp;
        </div>

        <AprosButton
          text="予約を確定する"
          comClassName="reserve"
          disabled={!formik.isValid || isError}
          onClick={async () => {
            try {
              const yykRes = await dispatch(
                reservationReflection({
                  termKbn: getTermKbn(),
                  henkoKbn: yykNo ? '2' : '1',
                  yykNo,
                  yykGetYmd,
                  yykGetHms,
                  kaiinCardNo: cardNumber,
                  kaiinNo: userId,
                  shopCode: tokCd,
                  sgyYmd,
                  sgyStaHm,
                  sgyEndHm: dayjs(sgyStaHm, 'HHmm')
                    .add(requiredTime ?? 0, 'm')
                    .format('HHmm'),
                  chubunCd,
                  shobunCd: shobun.map(sho => sho.shobunCd),
                  srySeq,
                  pcSendFlg: +mailAddressPcCheck,
                  mbSendFlg: +mailAddressMobileCheck,
                  otherAdr: otherMailAddressCheck ? otherAdr : '',
                  yykKbn: isMobila ? 'M' : yykKbn ? yykKbn : '1',
                  ecOrderNo,
                  kykBiko: biko,
                  tenpBiko,
                })
              ).unwrap();

              navigate(Path.RESERVATION_COMPLETE, {
                state: {
                  baseScreen,
                  yykInfo: {
                    ...yykInfo,
                    yykNo: yykNo ?? yykRes.body.yykNo,
                    work: yykInfo.work.map(w => ({
                      ...w,
                      sgyYmd,
                      sgyStaHm,
                    })),
                  },
                  selectService,
                  externalSelectSerivce,
                },
              });
            } catch (e) {
              if (/31600/.test(e)) {
                setIsError(true);
                var element = document.getElementById('root');
                element.scrollTop = 0;
              } else if (/30100|30400|30500/.test(e)) {
                setIsEdited(false);
                dispatch(
                  messageModalActions.showMessageModal({
                    message: `${e}:正常に予約が完了しませんでした。日時選択から別の時間を再度選択してください。`,
                    closeSameWithClick: true,
                    btnText: 'OK',
                    onClick: () => {
                      navigate(-1);

                      navigate(-1);

                      dispatch(messageModalActions.closeMessageModal());
                    },
                  })
                );
              } else {
                dispatch(
                  messageModalActions.showMessageModal({
                    message: e || COMMON_ERROR_MESSAGE,
                  })
                );
              }
            }
          }}
        />
        <div
          id="confirmDummyButton"
          className={`mt-[-40px] h-[40px] w-[260px] cursor-not-allowed rounded-[22px] opacity-0 
          ${!formik.isValid || isError ? 'block' : 'hidden'}
          `}
          onClick={() => {
            clickDummyButton();
          }}
        >
          &emsp;
        </div>
      </div>
      <div
        className={`fixed bottom-0 left-0 flex w-full flex-col items-center justify-center self-center border-t
        border-solid border-[#d0d0d9]  bg-white text-center transition-all duration-500 ease-in-out
        ${
          isBottomVisible ? 'h-[74px] opacity-100 sm:h-[87px]' : 'h-0 opacity-0'
        }`}
      >
        <div className="mb-[2px] mt-[-7px] flex flex-row items-center justify-center sm:mt-[-12px]">
          <Icons.Image
            src={Icons.alert}
            className="mt-2 h-6 w-6 sm:h-10 sm:w-10"
          />
          <label className="mt-2 text-[16px] font-bold sm:text-[24px]">
            {isModify
              ? '日時の変更がまだ確定していません'
              : '予約はまだ確定していません'}
          </label>
        </div>
        <div>
          <Linked
            text="注意事項の確認・予約確定へ進む"
            isLine={true}
            linkIcon={'R'}
            className="text-sm items-center justify-center self-center"
            onClick={() => scrollToBottom()}
          />
        </div>
      </div>
      {tireModal()}
    </>
  );
};

export default Confirm;
