import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { verifyLogin } from '../utils/verifyLoginUtil';
import { loadingActions } from '../slices/loadingSlice';
import {
  accountActions,
  fetchAccountRelatedInfo,
} from '../slices/accountSlice';
import { actions } from '../slices/loginSlice';
import React, { useLayoutEffect, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import Path from '../constants/routePath';
import Karte from '../pages/karte/karte';
import ShopSelect from '../pages/reservation/shopSelect';
import ShopAndServiceSelect from '../pages/reservation/shopAndServiceSelect';
import DateSelect from '../pages/reservation/dateSelect';
import Confirm from '../pages/reservation/confirm';
import Complete from '../pages/reservation/complete';
import ReservationList from '../pages/reservation/list';
import KarteDescription from '../pages/karte/karteDescription';
import FAQ from '../pages/faq/faq';
import Maintenance from '../pages/maintenance/maintenance';
import PitService from '../pages/pitService';
import TopPage from '../pages/topPage';
import Terms from '../pages/terms/terms';
import AfterLoginRoute from './afterLoginRoute';
import BeforeLoginRoute from './beforeLoginRoute';
import DefaultRoute from './defaultRoute';

const RootRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { accountInfo } = useSelector(state => state.account);

  // ログインしているか
  const [isLogin, setIsLogin] = useState(null);
  // 退会しているか
  const [isUnsubscribe, setIsUnsubscribe] = useState(false);

  // ログイン時に遷移できる画面
  const afterLogin = [
    { path: Path.KARTE, element: <Karte /> },
    { path: Path.RESERVATION_SHOP_SELECT, element: <ShopSelect /> },
    {
      path: Path.RESERVATION_SERVICE_SELECT,
      element: <ShopAndServiceSelect />,
    },
    { path: Path.RESERVATION_DATE_SELECT, element: <DateSelect /> },
    { path: Path.RESERVATION_CONFIRM, element: <Confirm /> },
    { path: Path.RESERVATION_COMPLETE, element: <Complete /> },
    { path: Path.RESERVATION_LIST, element: <ReservationList /> },
  ];

  // 未ログイン時に遷移できる画面
  const beforeLogin = [
    { path: Path.KARTE_DESCRIPTION, element: <KarteDescription /> },
  ];

  // ログイン状態にかかわらず遷移できる画面
  const freeLogin = [
    { path: Path.FAQ, element: <FAQ /> },
    { path: Path.MAINTENANCE, element: <Maintenance /> },
    { path: Path.PIT_SERVICE, element: <PitService /> },
    { path: Path.TOP, element: <TopPage /> },
    { path: Path.TERMS, element: <Terms /> },
  ];

  const asyncVerifyLogin = async () => {
    dispatch(loadingActions.setAuthLoading(true));
    try {
      const info = await verifyLogin(dispatch, accountInfo);
      if (info && info === 'unsubsucribe') {
        dispatch(accountActions.clearAccount());
        setIsLogin(0);
        setIsUnsubscribe(true);
      } else if (info && info.body?.rankCode !== '99') {
        dispatch(accountActions.updateAccountInfo({ info }));
        dispatch(accountActions.updateToken({ token: info.body.UIDSignature }));
        setIsLogin(1);
      } else if (info && info.body?.rankCode === '99') {
        if (location.pathname !== Path.TOP) {
          navigate(Path.TOP);
        }
        dispatch(actions.showCorporationLoginModal(true));
        dispatch(accountActions.clearAccount());
        setIsLogin(0);
      } else {
        dispatch(accountActions.clearAccount());
        setIsLogin(0);
      }
    } finally {
      dispatch(loadingActions.setAuthLoading(false));
    }
  };

  // 画面遷移時に認証処理
  useLayoutEffect(() => {
    asyncVerifyLogin();
  }, [location]);

  useUpdateEffect(() => {
    accountInfo?.cardNumber &&
      dispatch(fetchAccountRelatedInfo({ cardNumber: accountInfo.cardNumber }));
  }, [accountInfo]);

  return isLogin !== null ? (
    afterLogin.find(obj => obj.path === location.pathname) ? (
      <AfterLoginRoute isLogin={isLogin} isUnsubscribe={isUnsubscribe} />
    ) : beforeLogin.find(obj => obj.path === location.pathname) ? (
      <BeforeLoginRoute isLogin={isLogin} />
    ) : freeLogin.find(obj => obj.path === location.pathname) ? (
      <DefaultRoute isLogin={isLogin} />
    ) : (
      <Navigate to={Path.TOP} replace />
    )
  ) : afterLogin.find(obj => obj.path === location.pathname) ? (
    <></>
  ) : (
    <DefaultRoute isLogin={isLogin} />
  );
};
export default RootRoute;
