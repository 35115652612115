import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { numberFormat } from 'utils/stringUtil';
import TopIcon from 'components/topIcon/index';
import AprosButton from 'components/aprosButton/index';
import Linked from 'components/linked/index';
import BacsProgress from 'components/bacsProgress/index';
import Path from 'constants/routePath';
import { RANK_DIAMOND, RANK_ICONS, RANK_NAMES } from 'constants/rank';
import { displayCautionActions } from 'slices/displayCautionSlice';
import Icons from 'images/icons';
import { useDeviceSize } from 'hooks/useDeviceSize';
import './style.scss';
import dayjs from 'dayjs';
import classNames from 'classnames';
import LogoutModal from './modal/logoutModal';
import LoginGuidanceModal from './modal/loginGuidanceModal';
import {
  sapLogin,
  sapRegister,
  sapCardNumber,
  sapLogoutWithMessage,
} from '../../utils/sapSdkUtil';

const HeaderRow = ({ links, isLogin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isPc } = useDeviceSize();
  const { pathname } = location;

  const [showLogout, setLogout] = useState(false);
  const [showLoginGuidance, setLoginGuidance] = useState(false);

  const [isActive, setActive] = useState(false);
  const [isUserInfoMenuActive, setUserInfoMenuActive] = useState(false);

  const { accountInfo } = useSelector(state => state.account);

  isLogin = isLogin !== null ? (accountInfo?.userName ? isLogin : 0) : null;

  const userDetailToggle = () => {
    setActive(!isActive);
  };
  const userInfoMenuToggle = () => {
    setUserInfoMenuActive(!isUserInfoMenuActive);
  };

  const {
    cardNumber,
    rankCode,
    userName,
    shopPrefName,
    shopName,
    point,
    nextKaiinrnkKbn,
    stiRkuppntVal,
    nextRankBorder,
    fnlRtenYmd,
  } = accountInfo || {};

  const nextRankBorderNum = Number(nextRankBorder);
  const pointNum = Number(point);

  const percent =
    rankCode === RANK_DIAMOND ? 100 : (pointNum / nextRankBorderNum) * 100;

  const { cautionDisplayDate } = useSelector(state => state.displayCaution);

  const { isMobila } = useSelector(state => state.mobila);

  const headList = isLogin
    ? [
        {
          nm: '店舗から予約',
          onClick: () => navigate(Path.RESERVATION_SERVICE_SELECT),
        },
        {
          nm: 'ピットサービスから予約',
          onClick: () => navigate(Path.PIT_SERVICE),
        },
        { nm: '予約確認', onClick: () => navigate(Path.RESERVATION_LIST) },
        { nm: '車のカルテ', onClick: () => navigate(Path.KARTE) },
        { nm: '会員情報' },
        { nm: '車両情報', link: Path.USER_CAR_INFO },
      ]
    : [
        {
          nm: '店舗から予約',
          onClick: () => {
            if (!cautionDisplayDate) {
              dispatch(
                displayCautionActions.check(() =>
                  sapLogin(
                    process.env.REACT_APP_BASE_URL +
                      Path.RESERVATION_SERVICE_SELECT,
                    isMobila
                  )
                )
              );
            } else {
              sapLogin(
                process.env.REACT_APP_BASE_URL +
                  Path.RESERVATION_SERVICE_SELECT,
                isMobila
              );
            }
          },
        },
        {
          nm: 'ピットサービスから予約',
          onClick: () => navigate(Path.PIT_SERVICE),
        },
        {
          nm: '予約確認',
          onClick: () =>
            sapLogin(
              process.env.REACT_APP_BASE_URL + Path.RESERVATION_LIST,
              isMobila
            ),
        },
        { nm: '車のカルテ', onClick: () => navigate(Path.KARTE) },
        { nm: '会員サービスについて', link: Path.BRAND_SITE_LP },
      ];

  const smHeadTouch = () => {
    document.getElementById('headerRowMenuIcon').classList.remove('active');
    toggleSmMenuOpen();
  };
  const smHeadList =
    isLogin && accountInfo?.userName
      ? [
          {
            nm: '店舗から予約',
            onClick: () => {
              smHeadTouch();
              navigate(Path.RESERVATION_SERVICE_SELECT);
            },
          },
          {
            nm: 'ピットサービスから予約',
            onClick: () => {
              smHeadTouch();
              navigate(Path.PIT_SERVICE);
            },
          },
          {
            nm: '予約確認',
            onClick: () => {
              smHeadTouch();
              navigate(Path.RESERVATION_LIST);
            },
          },
          {
            nm: '車のカルテ',
            onClick: () => {
              smHeadTouch();
              navigate(Path.KARTE);
            },
          },
          { nm: '会員情報' },
          { nm: '車両情報', link: Path.USER_CAR_INFO },
          {
            nm: 'ログアウト',
            onClick: () => {
              setLogout(true);
            },
          },
        ]
      : [
          {
            nm: 'ログイン/新規会員登録',
            onClick: () => {
              smHeadTouch();
              setLoginGuidance(true);
            },
          },

          {
            nm: '店舗から予約',
            onClick: () => {
              smHeadTouch();
              if (!cautionDisplayDate) {
                document
                  .getElementById('headerRowMenuIcon')
                  .classList.remove('active');
                toggleSmMenuOpen();
                dispatch(
                  displayCautionActions.check(() =>
                    sapLogin(
                      process.env.REACT_APP_BASE_URL +
                        Path.RESERVATION_SERVICE_SELECT,
                      isMobila
                    )
                  )
                );
              } else {
                sapLogin(
                  process.env.REACT_APP_BASE_URL +
                    Path.RESERVATION_SERVICE_SELECT,
                  isMobila
                );
              }
            },
          },
          {
            nm: 'ピットサービスから予約',
            onClick: () => {
              smHeadTouch();
              navigate(Path.PIT_SERVICE);
            },
          },
          {
            nm: '予約確認',
            onClick: () => {
              smHeadTouch();
              sapLogin(
                process.env.REACT_APP_BASE_URL + Path.RESERVATION_LIST,
                isMobila
              );
            },
          },
          { nm: '車のカルテ', onClick: () => navigate(Path.KARTE) },
          {
            nm: '会員サービスについて',
            link: Path.BRAND_SITE_LP,
            onClick: () => {
              smHeadTouch();
            },
          },
        ];

  const userInfoMenuList = [
    {
      nm: '会員情報変更',
      nextline: ['(ご利用店舗・メール配信設定・住所等)'],
      onClick: () =>
        window.abclp.mypage.show({
          redirectURL: window.location.href,
          clpHost: process.env.REACT_APP_CLP_DOMAIN,
          target: 'clp/change',
        }),
    },
    {
      nm: 'ログインID変更',
      onClick: () =>
        window.abclp.mypage.show({
          redirectURL: window.location.href,
          clpHost: process.env.REACT_APP_CLP_DOMAIN,
          target: 'clp/mail',
        }),
    },
    {
      nm: 'パスワード変更',
      onClick: () =>
        window.abclp.mypage.show({
          redirectURL: window.location.href,
          clpHost: process.env.REACT_APP_CLP_DOMAIN,
          target: 'clp/password',
        }),
    },
    {
      nm: '他社ID連携',
      onClick: () =>
        window.abclp.mypage.show({
          redirectURL: window.location.href,
          clpHost: process.env.REACT_APP_CLP_DOMAIN,
          target: 'clp/social',
        }),
    },
    {
      nm: 'ログアウト',
      onClick: () => {
        setLogout(true);
      },
    },
    {
      nm: '退会',
      onClick: () =>
        window.abclp.mypage.show({
          redirectURL: process.env.REACT_APP_BASE_URL + Path.TOP,
          cancelRedirectURL: process.env.REACT_APP_BASE_URL + Path.TOP,
          clpHost: process.env.REACT_APP_CLP_DOMAIN,
          target: 'clp/cancel',
        }),
    },
    { nm: '会員サービスについて', link: Path.BRAND_SITE_LP },
  ];

  const diamondRankUp =
    rankCode === '01' && stiRkuppntVal === '0' && nextKaiinrnkKbn === '03';

  const sapphireRankUp =
    rankCode === '01' && stiRkuppntVal === '0' && nextKaiinrnkKbn === '02';

  // GTMカスタムイベントパラメータ
  const dataLayer = (window.dataLayer = window.dataLayer || []);
  dataLayer.push({
    customerNo: cardNumber,
  });

  //最終来店日が2023年9月30日までなら固定で失効日を2024年9月末とする
  const releaseDay = '2023-09-30';
  const isReleaseBefore = dayjs(fnlRtenYmd).isSameOrBefore(releaseDay);

  const userInfoArea = (
    <div className="mt-[29px] font-sans text-navy">
      <div className="font-medium">よくご利用いただく店舗</div>

      <div className="mt-1 font-bold">
        {shopPrefName} {shopName}
      </div>

      <div className="mb-4 mt-8 flex flex-col justify-center">
        <div className="text-[14px]">{userName}様の現在のランク</div>

        <div className="header_row--hamburger_menu--bold_font_18_16 mt-1 font-bold">
          {RANK_NAMES[rankCode]}会員
        </div>
      </div>

      <div className="flex items-end justify-between">
        <p>現在のバックス</p>

        <div className="flex items-end">
          <p className="header_row--hamburger_menu--bold_font_24_16 ml-4 mr-2 font-bold">
            {numberFormat(pointNum)}
          </p>

          <p>バックス</p>
        </div>
      </div>

      <BacsProgress percent={percent} />

      {rankCode !== RANK_DIAMOND && (
        <div className="mb-[6px]">
          {diamondRankUp
            ? '来月からダイヤモンド会員にランクアップします'
            : sapphireRankUp
            ? '来月からサファイア会員にランクアップします'
            : `${RANK_NAMES[nextKaiinrnkKbn]}会員まであと${numberFormat(
                nextRankBorderNum < pointNum ? 0 : nextRankBorderNum - pointNum
              )}バックス`}
        </div>
      )}

      <div className="header_row--hamburger_menu--font_16_12 mb-2 text-[#4C657C]">
        ※会員ランクは毎月末日時点での保有バックスで決定します。
      </div>

      <div className="mb-4 flex flex-col items-end">
        <Linked
          text={'ランクアップには'}
          href={Path.RANK_UP_INFO}
          className="font-bold"
          isLine
          linkIcon="R"
        />
        {/* 最終来店日があれば表示 */}
        {fnlRtenYmd && (
          <div>
            <div className="mt-8">
              最終来店日
              <div className="header_row--hamburger_menu--bold_font_24_16 mt-1 flex flex-row font-normal">
                <div className="font-bold">
                  {dayjs(fnlRtenYmd).format('YYYY')}
                </div>
                <div className="header_row--hamburger_menu--font_18_12 mt-[4px]">
                  年
                </div>
                <div className="font-bold">{dayjs(fnlRtenYmd).format('M')}</div>
                <div className="header_row--hamburger_menu--font_18_12 mt-[4px]">
                  月
                </div>
                <div className="font-bold">{dayjs(fnlRtenYmd).format('D')}</div>
                <div className="header_row--hamburger_menu--font_18_12 mt-[4px]">
                  日
                </div>
              </div>
            </div>
            <div className="header_row--hamburger_menu--font_16_12 -mb-0.5 mt-[6px] text-[#4C657C]">
              ※1年間ご来店がなかった場合、月末にバックスは失効します。
              失効予定日：
              {dayjs(isReleaseBefore ? releaseDay : fnlRtenYmd)
                .add(1, 'y')
                .format('YYYY年M月末')}
            </div>
          </div>
        )}
      </div>
    </div>
  );

  // PCとTB共用
  const tbUserInfoArea = (
    <div className="z-40">
      {isLogin === 1 ? (
        <div className="text-sm group relative flex items-center justify-start gap-2 font-medium text-navy">
          <Icons.Image
            src={RANK_ICONS[rankCode]}
            className="h-10 object-contain"
          />
          <div>
            <div>{userName + ' 様'}</div>
            <div>{'AB ID：' + cardNumber}</div>
          </div>
          <Icons.Image
            src={Icons.pulldown}
            className={classNames(
              `w-[20px] transform transition-all duration-100 ease-linear
              hover:rotate-180 group-hover:rotate-180
              `
            )}
          />

          <div
            className="duration-400 invisible absolute right-0 top-[54px] flex w-[350px] flex-col
                  gap-y-2 bg-[#F3F3F3] p-4 pb-6 opacity-0
                  drop-shadow-[0px_0px_6px_#00244529] transition-all
                  ease-linear hover:visible hover:opacity-100
                  group-hover:visible group-hover:opacity-100"
          >
            <div>よくご利用いただく店舗</div>

            <div className="font-bold">
              {shopPrefName} {shopName}
            </div>

            <div className="mb-4 mt-6 flex flex-col items-center justify-center">
              <div>{userName}様の現在のランク</div>

              <div className="mb-3 font-bold">{RANK_NAMES[rankCode]}会員</div>

              <Icons.Image
                src={RANK_ICONS[rankCode]}
                className="h-[95px] object-contain"
              />
            </div>

            <div className="flex items-end justify-center">
              <p>現在のバックス</p>

              <p className="text-base ml-4 mr-2 font-bold">
                {numberFormat(pointNum)}
              </p>

              <p>バックス</p>
            </div>

            <BacsProgress percent={percent} />

            {rankCode !== RANK_DIAMOND && (
              <>
                <div className="-mb-1">
                  {diamondRankUp
                    ? '来月からダイヤモンド会員にランクアップします'
                    : sapphireRankUp
                    ? '来月からサファイア会員にランクアップします'
                    : ''}
                </div>
                {!diamondRankUp && !sapphireRankUp && (
                  <>
                    <div className="-mb-1">
                      {RANK_NAMES[nextKaiinrnkKbn]}会員まであと
                    </div>
                    <div style={{ wordSpacing: '.1ch' }}>{`${numberFormat(
                      nextRankBorderNum < pointNum
                        ? 0
                        : nextRankBorderNum - pointNum
                    )} バックス`}</div>
                  </>
                )}
              </>
            )}

            <div className="text-xs -mb-0.5 text-[#4C657C]">
              ※会員ランクは毎月末日時点での保有バックスで決定します。
            </div>

            <div>
              <div className="-mt-[2px] flex flex-col items-end text-[18px]">
                <Linked
                  text={'ランクアップには'}
                  href={Path.RANK_UP_INFO}
                  isLine
                  linkIcon="R"
                  className="text-[18px] font-bold"
                />

                {/* 最終来店日があれば表示 */}
                {fnlRtenYmd && (
                  <div>
                    <div className="mt-6">
                      最終来店日
                      <div className="mt-2 flex flex-row text-[24px] font-normal">
                        <div className="font-bold">
                          {dayjs(fnlRtenYmd).format('YYYY')}
                        </div>
                        <div className="mt-[5px] text-[18px]">年</div>
                        <div className="font-bold">
                          {dayjs(fnlRtenYmd).format('M')}
                        </div>
                        <div className="mt-[5px] text-[18px]">月</div>
                        <div className="font-bold">
                          {dayjs(fnlRtenYmd).format('DD')}
                        </div>
                        <div className="mt-[5px] text-[18px]">日</div>
                      </div>
                    </div>
                    <div className="text-xs mt-2 text-[#4C657C]">
                      ※1年間ご来店がなかった場合、月末にバックスは失効します。
                      <div>
                        失効予定日：
                        {dayjs(fnlRtenYmd).add(1, 'y').format('YYYY年M月末')}
                      </div>
                    </div>
                  </div>
                )}
                <Linked
                  text={'ログアウト'}
                  isLine
                  onClick={() => setLogout(true)}
                  linkIcon="R"
                  className="text-[18px] font-bold"
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        isLogin === 0 && (
          <div className="flex h-[54px] items-center justify-end gap-6">
            <AprosButton
              text="ログイン/新規会員登録"
              comClassName="main"
              className="w-[260px]"
              onClick={() => setLoginGuidance(true)}
            />
          </div>
        )
      )}
    </div>
  );

  // ハンバーガーメニュー開閉
  const [smMenuOpen, setSmMenuOpen] = useState(false);
  const toggleSmMenuOpen = () => {
    setSmMenuOpen(!smMenuOpen);
  };

  // useEffect(() => {
  //   if (isPc) return;
  //   if (smMenuOpen) {
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     document.body.style.overflow = 'auto';
  //   }
  // }, [smMenuOpen]);
  useEffect(() => {
    if (isPc) return;
    if (smMenuOpen) {
      document.body.style.overflow = 'visible';
    } else {
      document.body.style.overflow = 'auto';
      // 会員詳細開いたまま閉じられた状態の場合、閉じる
      if (isActive) {
        userDetailToggle();
      }
      if (isUserInfoMenuActive) {
        userInfoMenuToggle();
      }
    }
  }, [smMenuOpen]);

  useEffect(() => {
    function handleGlobalClick(event) {
      // メニューが開いており、かつクリックした要素がメニュー外部にある場合
      if (
        smMenuOpen &&
        !document
          .getElementById('headerRowHamburgerMenu')
          .contains(event.target)
      ) {
        document.getElementById('headerRowMenuIcon').classList.remove('active');
        toggleSmMenuOpen();
      }
    }

    window.addEventListener('click', handleGlobalClick);

    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, [smMenuOpen]);

  // PC: 会員情報メニュー
  const userInfoMenu = (
    <div>
      <a
        className="group relative flex h-[54px] w-fit cursor-pointer
          items-center text-[18px] font-bold text-navy hover:text-[#647A8D]"
      >
        会員情報
        <div
          className="duration-400 header_row--user_info_menu--hover invisible absolute left-[-16px] top-[54px] z-50
            flex h-[384px] w-[356px] rounded-md
            bg-[#F3F3F3] opacity-0 drop-shadow-[0px_0px_6px_#00244529]
            transition-all ease-linear group-hover:visible group-hover:opacity-100"
        >
          <ul>
            <li>
              {userInfoMenuList.map((item, i) => (
                <Linked
                  key={i}
                  text={item.nm}
                  href={item.link}
                  onClick={() => item.onClick()}
                  className="p-3 px-4 text-[16px] font-bold"
                  nextline={item.nextline ? item.nextline : []}
                />
              ))}
            </li>
          </ul>
        </div>
      </a>
    </div>
  );

  // TBとスマホ共用: 会員情報メニュー
  const smUserInfoMenu = (
    <div className="header_row--hamburger_menu--inner text-left">
      <div
        className="relative -mb-[5px] flex cursor-pointer items-center justify-start gap-2 text-navy"
        onClick={userInfoMenuToggle}
      >
        <div>会員情報</div>
        <Icons.Image
          src={Icons.pulldown}
          className={classNames(
            'w-[20px] transform transition-all duration-100 ease-linear',
            isUserInfoMenuActive ? 'rotate-180' : ''
          )}
        />
      </div>
      <div
        className={classNames(
          'transition-max-height overflow-hidden duration-200 ease-in-out',
          isUserInfoMenuActive ? 'max-h-[390px] sm:max-h-[430px]' : 'max-h-0'
        )}
      >
        <ul className="relative top-[30px] h-[500px] px-4 font-sans">
          <li>
            {userInfoMenuList.map((item, i) => (
              <Linked
                key={i}
                text={item.nm}
                href={item.link}
                onClick={() => {
                  item.onClick();
                  smHeadTouch();
                }}
                className="mb-[30px]"
                nextline={item.nextline ? item.nextline : []}
              />
            ))}
          </li>
        </ul>
      </div>
    </div>
  );

  const menuLine = (
    <div className="my-[-10px] h-[1px] w-[100%] border-t border-line " />
  );

  const handleBeforeUnloadEvent = useCallback(event => {
    event.returnValue = '';
  }, []);

  return (
    <>
      <div className="header_row--hamburger_menu flex h-fit items-center justify-between px-4 pb-1 pt-4 lg:min-h-[96px] lg:items-center lg:px-6 lg:py-5">
        <TopIcon className="lg:self-start" />

        {pathname !== Path.MAINTENANCE && (
          <div className="flex flex-grow flex-wrap items-center justify-end">
            {isPc ? (
              // PCの場合
              <div className="hidden flex-wrap justify-end gap-x-12 gap-y-6 lg:flex">
                {isLogin && !links ? (
                  <></>
                ) : (
                  <ul>
                    <li className="flex h-[54px] flex-wrap items-center gap-x-7 gap-y-6">
                      {headList.map((item, i) => (
                        <div key={i}>
                          {item.nm !== '会員情報' ? (
                            <Linked
                              text={item.nm}
                              href={item.link}
                              onClick={item.onClick}
                              className="text-[18px] font-bold"
                            />
                          ) : (
                            <div className="group flex flex-wrap">
                              <div key={i}>{userInfoMenu}</div>
                              <Icons.Image
                                src={Icons.pulldown}
                                className={classNames(
                                  'ml-[5px] w-[20px] transform transition-all duration-100 ease-linear hover:rotate-180 group-hover:rotate-180'
                                )}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </li>
                  </ul>
                )}

                {tbUserInfoArea}
              </div>
            ) : (
              // TBとスマホの場合
              <div
                className="fixed z-30 flex items-start"
                id="headerRowHamburgerMenu"
              >
                <div
                  className="menu_icon"
                  id="headerRowMenuIcon"
                  onClick={e => {
                    e.target.classList.add('active');
                    toggleSmMenuOpen();
                  }}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>

                <div
                  className={`header_row--hamburger_menu--range fixed top-0 z-30 flex h-[100vh] flex-col items-center justify-start overflow-y-scroll bg-white transition-all duration-200 ease-linear ${
                    smMenuOpen ? 'opacity-[1]' : 'invisible opacity-20'
                  }`}
                >
                  <div className="flex w-full justify-end">
                    <Icons.Image
                      src={Icons.closeSp}
                      className="mr-2.5 mt-[11px] h-[50px] w-[50px]"
                      onClick={() => {
                        document
                          .getElementById('headerRowMenuIcon')
                          .classList.remove('active');
                        toggleSmMenuOpen();
                        // 会員詳細開いたまま×ボタンをしたら、閉じる
                        if (isActive) {
                          userDetailToggle();
                        }
                        if (isUserInfoMenuActive) {
                          userInfoMenuToggle();
                        }
                      }}
                    />
                  </div>

                  {/* ハンバーガーメニュー内容  */}
                  <div className="header_row--hamburger_menu--base_font mb-[40px] mt-3 flex w-full flex-col items-start justify-start gap-8 px-10 ">
                    {/* ユーザー情報 */}
                    {isLogin === 1 && (
                      <div className="header_row--hamburger_menu--inner mb-[20px] text-left">
                        <div
                          className="relative -mb-[5px] flex cursor-pointer items-center justify-start gap-2 text-navy"
                          onClick={userDetailToggle}
                        >
                          <Icons.Image
                            src={RANK_ICONS[rankCode]}
                            className="peer h-10 object-contain"
                          />
                          <div>
                            <div>{userName + ' 様'}</div>
                            <div>{'AB ID：' + cardNumber}</div>
                          </div>
                          <Icons.Image
                            src={Icons.pulldown}
                            className={classNames(
                              'w-[20px] transform transition-all duration-100 ease-linear',
                              isActive ? 'rotate-180' : ''
                            )}
                          />
                        </div>
                        <div
                          className={classNames(
                            'transition-max-height overflow-hidden duration-500 ease-in-out',
                            isActive ? 'max-h-[1000px]' : 'max-h-0'
                          )}
                        >
                          {userInfoArea}
                        </div>
                      </div>
                    )}
                    {isLogin === 1 && menuLine}
                    {smHeadList.map((item, i) => (
                      <>
                        <div key={i}>
                          {item.nm !== '会員情報' ? (
                            <Linked
                              text={item.nm}
                              href={item.link}
                              onClick={() => {
                                item.onClick();
                                toggleSmMenuOpen();
                              }}
                            />
                          ) : (
                            <div key={i}>{smUserInfoMenu}</div>
                          )}
                        </div>
                        {isLogin === 1 &&
                          (item.nm === '予約確認' ||
                            item.nm === '車のカルテ' ||
                            item.nm === '車両情報') &&
                          menuLine}
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <LogoutModal
        isModalOpen={showLogout}
        closeModal={() => setLogout(false)}
        onClick={() => {
          setLogout(false);
          window.addEventListener(
            'beforeunload',
            handleBeforeUnloadEvent,
            true
          );
          sapLogoutWithMessage(accountInfo.cardNumber, navigate, dispatch);
        }}
      />
      <LoginGuidanceModal
        isModalOpen={showLoginGuidance}
        closeModal={() => setLoginGuidance(false)}
        onLoginClick={() => sapLogin(window.location.href, isMobila)}
        onCardNumberClick={() => sapCardNumber(window.location.href, isMobila)}
        onRegisterClick={() => sapRegister(window.location.href, isMobila)}
      />
    </>
  );
};

export default HeaderRow;
